<template>
  <div class="page">
    <h1><w-icon>mdi mdi-magnify</w-icon>有効性 の 調査</h1>
    <div class="content">
      <div class="image">
        <w-icon>mdi mdi-hubspot</w-icon>
      </div>
      <p>
        &emsp;学習カードを「わけりんぐ」にセットすると、学習しながら不正解だった問題だけを簡単に集められるようになります。
        この特性が学習に対してどのような効果を与えてくれるのでしょうか？
        <br>&emsp;最近では個人による情報発信が簡単に行えるようになったため、例えば東大生や学習系のYoutuberなど、
        数多くの方がＳＮＳなどを通して、効果的な学習方法について発信されています。
        今回は、調査形式で「わけりんぐ」を使った際の学習への有効性を、様々な角度から探っていこうと思います。
      </p>
      <h2>
        <w-icon>mdi mdi-backup-restore</w-icon>
        そもそも
      </h2>
      <w-divider/>
      <p>
        &emsp;そもそも、「わけりんぐ」の開発を始めたきっかけは、
        <a href="https://minne.com/@mihiraku/letters/28766" target="_blank" rel="noopener noreferrer">
          minneのレター
        </a>
        でも書きましたように、
        娘が小学校の宿題として、かけ算九九の学習カードに取り組んでいる最中のことでした。
        「さざんがきゅう」といったように声に出しながら問題を解いてカードをめくり裏の解答を確認する
        といった手順をテキパキとこなしているのですが、何度も同じ問題で間違えてしまっていることに気づいたことと、
        「隣の子は間違ってるけど終わらせるのが早くてすごい」と娘から聞いた際に、
        「間違えた問題だけを簡単に再学習できればいいのにな」と、思ったことがきっかけでした。
        <br>&emsp;しかし、そもそも、表に問題が書かれ裏に正解が書かれた学習カードを使った学習には、
        どれほどの学習効果があるのでしょうか？ 根本的な学習方法の調査から始めました。
      </p>
      <h2>
        <w-icon>mdi mdi-cards-outline</w-icon>
        学習カード で 学ぼう
      </h2>
      <w-divider/>
      <p>
        &emsp;効果的な学習方法には、下記のポイントがあることがわかりました。
      </p>
      <ul>
        <li>エビングハウスの忘却曲線（人間の忘れやすさ）<sup>注１</sup></li>
        <li>復習で短期記憶から長期記憶へ<sup>注１注２</sup></li>
        <li>思い出すことを重視する<sup>注２</sup></li>
      </ul>
      <p>
        &emsp;もともと人間は忘れやすく、20分後には42%忘れ、一日後には67%忘れてしまうものであるが、
        復習することによって短期記憶から長期記憶となり、記憶した内容を忘れにくくなるということを知りました<sup>注１</sup>。
        ただし ここで、定期的に教科書や参考書を読み返すといった復習方法ではなく、
        問題を解いて「思い出す」という復習方法が長期記憶をより形成しやすい、ということを学びました<sup>注２</sup>。
        <br>&emsp;つまり、表に問題を書いて裏に正解を書いた学習カードは、
        問題を解くようにして復習することができるため、長期記憶の形成にとってより効果的であるという調査結果となりました。
        <br>&emsp;さらに、学習カードは、安く入手できて、順序のシャッフルが可能で、裏返しても有用に学習できる など、
        優れた勉強道具として紹介されていました<sup>注３</sup>。
        <w-card class="bdrs3" title-class="blue-light5--bg" content-class="text-center">
          <template #title>
            <w-icon>mdi mdi-chart-timeline-variant-shimmer</w-icon>
            <span class="ml2">長期記憶を形成するには？</span>
          </template>
          <span class="title2"><b>学習カード 効果あり</b></span>
        </w-card>
      </p>
      <ul class="reference">
        <li>
          注１） 岡野タケシ弁護士
          <a href="https://www.youtube.com/watch?v=rBPqinIHqX0" target="_blank" rel="noopener noreferrer">
            ”今すぐやめろ！記憶・暗記を無駄にする3つのやりがちな行動とは？/ タケシ弁護士【岡野武志】”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=rBPqinIHqX0＞
          （参照日2022年3月29日）
        </li>
        <li>
          注２） からわかラボ
          <a href="https://www.youtube.com/watch?v=uHENyvtl_fY" target="_blank" rel="noopener noreferrer">
            ”【科学的】本当に効果のある暗記方法4選【勉強のコツ】”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=uHENyvtl_fY＞
          （参照日2022年3月29日）
        </li>
        <li>
          注３） 海外塾講師ヒラ
          <a href="https://www.youtube.com/watch?v=g9jWDI7858w" target="_blank" rel="noopener noreferrer">
            ”神・勉強道具これしかない！【6つのメリットと使い方】”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=g9jWDI7858w＞
          （参照日2022年3月29日）
        </li>
      </ul>
      <p>
        <w-icon>mdi mdi-check</w-icon>
        「わけりんぐ」では学習カードを扱った効果的な学習を行うことができます。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/item/wake-ring/how-to-use/"> ＣＧシミュレーション</router-link>
        ・<router-link to="/item/wake-ring/movie/"> 動画</router-link>
      </p>
      <p>
        &emsp;つぎは、学習カードの効果的な使い方について調査を進めていきます。
      </p>
      <h2>
        <w-icon>mdi mdi-tag-search-outline</w-icon>
        未習得カード に 集中しよう
      </h2>
      <w-divider/>
      <p>
        &emsp;学習カードや単語帳を使った効果的な学習方法の紹介では、どの方も表現は違えど、
        学習済みのものと、未学習なものとに分けて、未学習なものに集中して学習を行うべきと紹介されている方が多かったです。
        ただし、仕分けの方法は様々で、カードをリングから取り除いたり、記憶の度合いに応じて印や箱を使って管理したりする方法などがありました。
      </p>
      <ul>
        <li>正解したカードを、リングから外していく<sup>注１注２</sup></li>
        <li>覚えたものと覚えていないものを、分ける<sup>注３</sup></li>
        <li>正解する度に右の箱に移動する<sup>注４</sup></li>
        <li>記憶の度合いに応じた印をつける（単語帳）<sup>注５</sup></li>
      </ul>
      <p>
        &emsp;それぞれの記事・動画を拝見していて特に印象的だったのは、学習を行いながら仕分けていくことで、
        「これから覚えるものが目に見えてどんどん減っていく」ため意欲的に学習を続けられるといった観点でした。
        <br>&emsp;したがって、学習カードは習得済みと未習得なものに分けて学習を進めるべきという調査結果となりました。
      </p>
      <w-card class="bdrs3" title-class="blue-light5--bg" content-class="text-center">
        <template #title>
          <w-icon>mdi mdi-chart-timeline-variant-shimmer</w-icon>
          <span class="ml2">カードを分ける必要ある？</span>
        </template>
        <span class="title2"><b>仕分けて 未習得に集中すべき</b></span>
      </w-card>
      <ul class="reference">
        <li>
          注１） まとめシート流！絶対合格チャンネル 【資格試験・中小企業診断士試験対策】
          <a href="https://www.youtube.com/watch?v=M4z5bQAso30" target="_blank" rel="noopener noreferrer">
            ”暗記のための強い味方、暗記カードの使い方講座【資格試験対策】 第013回”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=M4z5bQAso30＞
          （参照日2022年3月29日）
        </li>
        <li>
          注２） 大学受験合格チャンネル
          <a href="https://www.youtube.com/watch?v=9lZ0oXofybs" target="_blank" rel="noopener noreferrer">
            ”暗記効率3倍速!単語カードの効果的な使い方3つのポイント”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=9lZ0oXofybs＞
          （参照日2022年3月29日）
        </li>
        <li>
          注３） 早稲田集中力研究会 公式サイト
          <a href="https://shuchuryoku.jp/?p=2133" target="_blank" rel="noopener noreferrer">
            ”完璧に暗記するならカードがベストである理由”
          </a>
          https://shuchuryoku.jp/?p=2133
          （参照日2022年3月29日）
        </li>
        <li>
          注４） からわかラボ
          <a href="https://www.youtube.com/watch?v=uHENyvtl_fY" target="_blank" rel="noopener noreferrer">
            ”【科学的】本当に効果のある暗記方法4選【勉強のコツ】”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=uHENyvtl_fY＞
          （参照日2022年3月29日）
        </li>
        <li>
          注５） Morite2 English Channel
          <a href="https://www.youtube.com/watch?v=PJT9UWM0sz0" target="_blank" rel="noopener noreferrer">
            ”最強の英単語暗記法【グルグル勉強法】”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=PJT9UWM0sz0＞
          （参照日2022年3月29日）
        </li>
      </ul>
      <p>
        ここで、一発合格まとめシートの著者の方の動画がとてもわかり易く参考になりましたので、ご紹介させていただきます。
      </p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/M4z5bQAso30?start=116" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <ul class="reference">
        <li>
          引用元： まとめシート流！絶対合格チャンネル 【資格試験・中小企業診断士試験対策】
          <a href="https://www.youtube.com/watch?v=M4z5bQAso30" target="_blank" rel="noopener noreferrer">
            ”暗記のための強い味方、暗記カードの使い方講座【資格試験対策】 第013回”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=M4z5bQAso30＞
          （参照日2022年3月29日）
        </li>
      </ul>
      <p>
        野網様の丁寧なご解説動画に感謝いたします、どうも有難うございます。
      </p>
      <p>
        <w-icon>mdi mdi-check</w-icon>
        「わけりんぐ」で学習カードを扱うと、学習しながら不正解だった問題だけを簡単に集められるようになります。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/item/wake-ring/how-to-use/"> ＣＧシミュレーション</router-link>
        ・<router-link to="/item/wake-ring/movie/"> 動画</router-link>
      </p>
      <p>
        &emsp;さらに、調査中に学んだ効果的な学習時間についてご紹介します。
      </p>
      <h2>
        <w-icon>mdi mdi-av-timer</w-icon>
        学習時間は短く
      </h2>
      <w-divider/>
      <p>
        &emsp;学習の最適な時間についての紹介では、一番最適な定まった時間というものは見つけられませんでしたが、
        疲れてきても継続して長く学習を続けるべきという見解は、ひとつも見つけることができませんでした。
        いずれも、一般的な学習時間のイメージよりも、はるかに短い時間が最適だと、おっしゃっている方が多かったです。
      </p>
      <ul>
        <li>覚えた後に40秒の復習を行う<sup>注１</sup></li>
        <li>短時間きざみで学習する<sup>注２</sup></li>
        <li>疲れる前に休憩をとる<sup>注２</sup></li>
      </ul>
      <p>
        &emsp;なかでも、早稲田集中力研究会 中西さんの動画<sup>注２</sup>は、少し独特な動画の始まりとなっていますが、
        家で勉強に集中する仕組みを、順序立てて・体系立てて・そして率直にご説明されています。
        誰でも新しい気付きを発見して少し目が覚めるような動画となっていますので、
        ご興味がある方は一度ご覧になるとよいかもしれません。
      </p>
      <w-card class="bdrs3" title-class="blue-light5--bg" content-class="text-center">
        <template #title>
          <w-icon>mdi mdi-chart-timeline-variant-shimmer</w-icon>
          <span class="ml2">最適な学習時間は？</span>
        </template>
        <span class="title2"><b>みじかく 区切る</b></span>
      </w-card>
      <ul class="reference">
        <li>
          注１） メンタリスト DaiGo
          <a href="https://www.youtube.com/watch?v=PEn_Agkvr5I" target="_blank" rel="noopener noreferrer">
            ”記憶の残り方が段違い【40秒勉強法】とは”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=PEn_Agkvr5I＞
          （参照日2022年3月29日）
        </li>
        <li>
          注２） 早稲田集中力研究会
          <a href="https://www.youtube.com/watch?v=n0Xenx8vJgE" target="_blank" rel="noopener noreferrer">
            ”家で勉強に集中する方法”
          </a>
          Youtube.＜https://www.youtube.com/watch?v=n0Xenx8vJgE＞
          （参照日2022年3月29日）
        </li>
      </ul>
      <p>
        <w-icon>mdi mdi-check</w-icon>
        「わけりんぐ」で学習カードを扱うと、学習の中断と再開を非常にスムーズに繰り返すことができます。
        <br><w-icon>mdi mdi-arrow-right</w-icon>
        <router-link to="/item/wake-ring/how-to-use/"> ＣＧシミュレーション</router-link>
        ・<router-link to="/item/wake-ring/movie/"> 動画</router-link>
      </p>
      <h2>
        <w-icon>mdi mdi-text-box-check-outline</w-icon>
        まとめ
      </h2>
      <w-divider/>
      <p>
        &emsp;それでは、今回の調査をまとめますと、「わけりんぐ」で学習カードを扱う学習方法は、
        特に下記の点において有効だということがわかりました。
      </p>
      <w-card class="bdrs3" title-class="blue-light5--bg" content-class="text-center">
        <template #title>
          <w-icon>mdi mdi-microphone-question-outline</w-icon>
          <span class="ml2">「わけりんぐ」で学習カードを扱うと？</span>
        </template>
        <ol class="title2 lh3 no-point">
          <li><b>
            <w-icon color="success">mdi mdi-check-bold</w-icon>
            <w-icon>mdi mdi-memory</w-icon>
             思い出す形式の&nbsp;復習で&nbsp;長期記憶化する
          </b></li>
          <li><b>
            <w-icon color="success">mdi mdi-check-bold</w-icon>
            <w-icon>mdi mdi-chart-timeline-variant-shimmer</w-icon>
            一目瞭然な&nbsp;習得ステータスによって&nbsp;意欲が継続する
          </b></li>
          <li><b>
            <w-icon color="success">mdi mdi-check-bold</w-icon>
            <w-icon>mdi mdi-timer-outline</w-icon>
            学習の開始&nbsp;中断&nbsp;再開が&nbsp;瞬時に可能となる
          </b></li>
          <li><b>
            <w-icon color="success">mdi mdi-check-bold</w-icon>
            <w-icon>mdi mdi-lock-reset</w-icon>
            カードを外さずに&nbsp;仕分けられる
          </b></li>
        </ol>
      </w-card>
      <p>
        &emsp;つまり、以前はカードをリングから外して並び替えることが困難であった小学校の低学年生であっても、
        電車内などの狭い場所や、試験会場の短い休憩時間であっても、
        「わけりんぐ」であれば、仕分けを利用した効果的な学習が、
        いつでも どこでも 誰でも簡単にできるようになることに、
        ぜひ注目していただきたいと思います。
        現に、娘は小学校の低学年生から「わけりんぐ」を利用して、都道府県や地図記号などを学習してきました。
        <br><br>&emsp;いつでも、どこでも、どなたにとっても効果的な学習が実現できる「わけりんぐ」、
        <br>&emsp;ぜひ、これからの学習のお供に ご活用していただけますと 誠に幸いです
        <w-icon>mdi mdi-owl</w-icon>
        <w-divider/>
      </p>
      <div class="next-link">
        <w-flex align-center justify-space-evenly>
          <router-link to="/item/wake-ring/merit/">仕組みとメリット</router-link>
          <router-link to="/item/wake-ring/how-to-use/">ＣＧシミュレーション</router-link>
          <router-link to="/item/wake-ring/movie/">動画</router-link>
          <a href="https://minne.com/@mihiraku" target="_blank" rel="noopener noreferrer">ショップ <w-icon>mdi mdi-open-in-new</w-icon></a>
        </w-flex>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.content {
  .image {
    padding: 30px 0;
    .w-icon {
      font-size: 300%;
      margin: 0 13px;
    }
  }
}
</style>
